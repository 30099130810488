/* Homepage2.css */

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
      transform: translateX(-100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 2s ease-in-out;
}

.animate-slide-in-left {
  animation: slideInLeft 1s ease-out;
}
